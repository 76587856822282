import React, { VFC } from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Chip from "@material-ui/core/Chip"
import LinearProgress from "@material-ui/core/LinearProgress"
import DirectionsRun from "@material-ui/icons/DirectionsRun"
import CheckCircle from "@material-ui/icons/CheckCircle"
import { useLazyQuery } from "@apollo/client"
import Layout from "../components/Layout"
import { useForm } from "react-hook-form"
import { AdminSQLQuery, AdminSQLQueryVariables } from "../../../__generated__/AdminSQLQuery"
import { ADMIN_SQL_QUERY } from "../queries"
import { fromGlobalId } from "../../../common/global-ids"
import green from "@material-ui/core/colors/green"
import blue from "@material-ui/core/colors/blue"
import red from "@material-ui/core/colors/red"

type PickActionVariant = "PICK_CREATED" | "PICK_UPDATED" | "PICK_DELETED" | "TIEBREAKER_UPDATED" | "TIEBREAKER_DELETED"
type PickActionProps = {
  variant: PickActionVariant
}

const PickAction: VFC<PickActionProps> = ({ variant }) => {
  switch (variant) {
    case "PICK_CREATED":
      return <Chip icon={<CheckCircle style={{ color: "inherit" }} />} label="Pick Created" style={{ background: green[500], color: green[50] }} />
    case "PICK_UPDATED":
      return <Chip icon={<CheckCircle style={{ color: "inherit" }} />} label="Pick Updated" style={{ background: blue[500], color: blue[50] }} />
    case "PICK_DELETED":
      return <Chip icon={<CheckCircle style={{ color: "inherit" }} />} label="Pick Deleted" style={{ background: red[500], color: red[50] }} />
    case "TIEBREAKER_UPDATED":
      return (
        <Chip
          icon={<DirectionsRun style={{ color: "inherit" }} />}
          label="Tiebreaker Updated"
          variant="outlined"
          style={{ borderColor: blue[800], color: blue[800] }}
        />
      )
    case "TIEBREAKER_DELETED":
      return (
        <Chip
          icon={<DirectionsRun style={{ color: "inherit" }} />}
          label="Tiebreaker Deleted"
          variant="outlined"
          style={{ borderColor: red[800], color: red[800] }}
        />
      )
    default:
      return null
  }
}

const TableLabel: VFC<{ row: any }> = (props) => {
  const { action, slotId, tiebreakerQuestionLabel } = props.row
  const pickActionVariant: PickActionVariant = action
  switch (pickActionVariant) {
    case "PICK_CREATED":
    case "PICK_UPDATED":
    case "PICK_DELETED":
      return slotId ?? "-"
    case "TIEBREAKER_UPDATED":
    case "TIEBREAKER_DELETED":
      return tiebreakerQuestionLabel ?? "-"
    default:
      return "UNKNOW ACTION"
  }
}

const TableValue: VFC<{ row: any }> = (props) => {
  const { action, teamAbbrev, tiebreakerAnswer } = props.row
  const pickActionVariant: PickActionVariant = action
  switch (pickActionVariant) {
    case "PICK_CREATED":
    case "PICK_UPDATED":
    case "PICK_DELETED":
      return teamAbbrev ?? "-"
    case "TIEBREAKER_UPDATED":
    case "TIEBREAKER_DELETED":
      return tiebreakerAnswer ?? "-"
    default:
      return "UNKNOW ACTION"
  }
}

const PickLog: VFC = () => {
  const { register, handleSubmit } = useForm()
  const [query, { data, loading, error }] = useLazyQuery<AdminSQLQuery, AdminSQLQueryVariables>(ADMIN_SQL_QUERY, {})

  const onSubmit = React.useCallback(
    (data) => {
      const { poolId, entryId } = data
      const decodedPoolId = fromGlobalId(poolId)
      const decodedEntryId = fromGlobalId(entryId)
      query({
        variables: {
          statement: `SELECT p.id, 
          p.createdAt,
          p.periodId,
          p.slotId,
          p.itemId,
          p.action,
          p.userId,
          p.tiebreakerQuestionId,
          p.tiebreakerAnswer,
          t.abbrev as teamAbbrev,
          u.userLogin as userLogin,
          q.label as tiebreakerQuestionLabel
            FROM PickLog p
            LEFT JOIN Team t on t.id = p.itemId
            LEFT JOIN User u on u.id = p.userId
            LEFT JOIN TiebreakerQuestion q on q.id = p.tiebreakerQuestionId
            where poolId=${decodedPoolId.id} AND entryId=${decodedEntryId.id}`,
        },
      })
    },
    [query],
  )

  let tableBody
  if (loading) {
    tableBody = (
      <TableRow>
        <TableCell colSpan={5}>
          <LinearProgress />
        </TableCell>
      </TableRow>
    )
  } else {
    if (error) {
      tableBody = (
        <TableRow>
          <TableCell colSpan={5}>Error</TableCell>
        </TableRow>
      )
    } else {
      tableBody = (data?.sql ?? []).map((row) => (
        <TableRow key={row.id}>
          <TableCell>{new Date(row.createdAt).toLocaleString()}</TableCell>
          <TableCell>{row.userLogin}</TableCell>
          <TableCell>
            <PickAction variant={row.action} />
          </TableCell>
          <TableCell>
            <TableLabel row={row} />
          </TableCell>
          <TableCell>
            <TableValue row={row} />
          </TableCell>
        </TableRow>
      ))
    }
  }

  return (
    <Layout title="Pick Log">
      <Grid container spacing={6}>
        <Grid item xs={12} container spacing={4} alignItems="center" component="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <Typography variant="body2">
              Enter Pool and Entry <strong>global ids</strong> and click the <strong>Search</strong> button
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <TextField variant="outlined" name="poolId" label="Pool Global Id" inputRef={register} fullWidth required />
          </Grid>
          <Grid item xs={5}>
            <TextField variant="outlined" name="entryId" label="Entry Global Id" inputRef={register} fullWidth required />
          </Grid>
          <Grid item xs={2}>
            <Button type="submit" variant="contained" fullWidth color="primary">
              Search
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Time</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Change</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{tableBody}</TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default PickLog
